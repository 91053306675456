<template>
  <!--<modal id="add-contact">
    <template #title>Add Contact</template>
    <template #content>
      <p>Select people to add as a contact for <strong>{{ clientName }}</strong>.</p>
      <ul class="contacts-list">
        <promise invoke="getChatterinos">
          <template #content="props">
            <li v-for="contact in props.data" :key="contact.wfm_staff_uuid" class="contacts-list__item" :class="{'contacts-list__item--selected': selected.has(contact.wfm_staff_uuid)}">
              <button @click="selectContact(contact)">
                <div class="userinfo">
                  <Avatar :src="contact.avatar" :string="contact.name" class="mr-3"/>
                  <div class="userinfo__details-container">
                    <span class="userinfo__name">{{ contact.name }}</span>
                    <span class="userinfo__email">{{ contact.email }}</span>
                  </div>
                </div>
              </button>
            </li>
          </template>
        </promise>
      </ul>
    </template>
    <template #footer>
      <button class="button"
              :disabled="selected.size === 0"
              @click="addContacts">Add{{ selected.size ? ` ${selected.size} contacts` : '' }}</button>
    </template>
  </modal>-->
  <div class="d-flex align-items-center justify-content-between mb-3">
    <h3 class="mt-0 mb-0">{{ title }}</h3>
    <!--<button class="button button--sm" v-open-modal="'add-contact'">Add Contact</button>-->
  </div>
  <table class="table table--sm table--align-left table--scrollable">
    <thead>
      <tr>
        <th>Name</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="contact in clientContacts" :key="contact.contactUUID">
        <td>
          <!--<div class="d-flex align-items-center justify-content-between">-->
            <div class="userinfo">
              <Avatar :src="contact.avatar" :string="contact.name" class="userinfo__image-container--md mr-3"/>
              <div class="userinfo__details">
                <span class="userinfo__name">{{ contact.name }}</span>
                <a v-if="contact.email" :href="`mailto:${contact.email}`" target="_blank" rel="noopener" class="userinfo__email heading-desc">{{ contact.email }}</a>
                <a v-if="contact.mobile" :href="`tel:${contact.mobile}`" target="_blank" rel="noopener" class="userinfo__number heading-desc">{{ contact.mobile }}</a>
              </div>
            </div>
            <!--<button class="button button--icon button--grey" :aria-label="`Remove ${contact.name} as a Key Contact`">
              <inline-svg :src="require('@/assets/icons/trash.svg')" stroke="currentColor" width="14" height="14"/>
            </button>-->
          <!--</div>-->
        </td>
      </tr>
      <!-- empty -->
      <tr v-if="clientContacts.length === 0" class="table__empty">
        <td>No contacts have been added.</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
// import Promise from '@/components/promise.vue'
// import Modal from '@/components/modal.vue'
import Avatar from '@/components/avatar.vue'

export default {
  emits: ['add-contacts'],
  props: {
    /**
     * Title shown above the table of contacts
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * Client name to show in the modal description
     */
    clientName: {
      type: String,
      required: true,
    },
    /**
     * Array of contacts to show in the table
     */
    clientContacts: {
      type: Array,
      required: true,
    },
  },
  components: {
    // Promise,
    // Modal,
    Avatar,
  },
  data() {
    return {
      selected: new Set(),
    };
  },
  methods: {
    selectContact({ wfm_staff_uuid }) {
      if (!this.selected.has(wfm_staff_uuid)) {
        this.selected.add(wfm_staff_uuid);
      } else {
        this.selected.delete(wfm_staff_uuid);
      }
    },
    addContacts() {
      this.$emit('add-contacts', this.selected);
      this.selected.clear();
      this.closeModal('add-contact');
    },
  },
}
</script>