<template>
  <modal id="add-client-project-link">
    <template #title>
      <slot name="modal-title">Add Document</slot>
    </template>
    <template #content>
      <slot name="modal-desc">Add a document/url to share with the client.</slot>
      <form ref="addDocumentForm" id="addDocumentForm" class="mt-3" @submit.prevent="addDocument">
        <div class="form-group">
          <label for="add-document-title">Title <span class="desc">File name will be used if no title is provided.</span></label>
          <input type="text" id="add-document-title" name="title" class="form-control" placeholder="Name" required/>
        </div>
        <div class="form-group">
          <label>Document type <abbr class="required">*</abbr></label>
          <div class="form-group">
            <label for="add-document-type-file" class="form-checkbox">
              <input type="radio" name="document_type" id="add-document-type-file" class="form-checkbox" value="file" v-model="documentType" required/>
              <span class="form-checkbox__label">File Upload</span>
            </label>
            <label for="add-document-type-url" class="form-checkbox ml-3">
              <input type="radio" name="document_type" id="add-document-type-url" class="form-checkbox" value="url" v-model="documentType" required/>
              <span class="form-checkbox__label">External URL</span>
            </label>
          </div>
        </div>
        <div v-if="documentType === 'file'" class="form-group">
          <label for="add-document-file">File <abbr class="required">*</abbr></label>
          <input type="file" id="add-document-file" name="file" class="form-control" required/>
        </div>
        <div v-else-if="documentType === 'url'" class="form-group">
          <label for="add-document-url">URL <abbr class="required">*</abbr></label>
          <input type="url" id="add-document-url" name="url" class="form-control" placeholder="URL" required/>
        </div>
        <div class="form-group">
          <label for="add-document-share-with-client" class="form-checkbox">
            <input type="checkbox" id="add-document-share-with-client" name="shareable_with_client"/>
            <span class="form-checkbox__label">Share with client</span>
          </label>
        </div>
      </form>
      <div v-if="error" class="notification notification--error mt-3">
        <h5 class="notification__title">Error</h5>
        <span class="notification__error">There was an error adding your document, try again later.<br/><br/>{{ error }}</span>
      </div>
    </template>
    <template #footer>
      <button type="submit" class="button" form="addDocumentForm">Add</button>
    </template>
  </modal>
  <div class="d-flex align-items-center justify-content-between mb-3">
    <h3 class="mt-0 mb-0"><slot name="title">Documents</slot></h3>
    <button class="button button--sm" v-open-modal="'add-client-project-link'">Add Document</button>
  </div>
  <div class="table-scroll-wrapper">
    <table class="table table--sm table--align-left">
      <thead>
        <tr>
          <th width="70%">Title</th>
          <th width="10%">Share</th>
          <th width="10%" class="no-hover">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="document in documents" :key="document.id">
          <td>
            <a :href="document.url" target="_blank" rel="noopener" class="link__link">
              <span class="link__title">{{ document.title }}</span>
              <span class="link__url">{{ document.document_type === 'file' ? document.original_filename : document.url }}</span>
            </a>
          </td>
          <td>
            <label class="form-checkbox disabled">
              <input type="checkbox" :checked="document.shareable_with_client" disabled/>
              <span class="form-checkbox__label"></span>
            </label>
          </td>
          <td class="text-center">
            <button class="button button--icon button--grey" :aria-label="`Remove document named ${document.title}`" @click="deleteDocument(document)">
              <inline-svg :src="require('@/assets/icons/trash.svg')" stroke="currentColor" width="14" height="14"/>
            </button>
          </td>
        </tr>
        <!-- empty -->
        <tr v-if="isEmptyObject(documents)" class="table__empty">
          <td colspan="4">No documents have been added.</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Modal from '@/components/modal.vue'
import { httpPost, httpDelete } from '@/http'
import { isEmptyObject } from '@/utils'

export default {
  emits: ['add', 'delete'],
  props: {
    endpoint: {
      type: String,
      required: true,
    },
    documents: {
      type: Array,
      required: true,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      documentType: 'file',
      error: null,
    };
  },
  methods: {
    isEmptyObject,
    addDocument() {
      const { id } = this.$route.params;
      this.error = null;

      // transform "on" value to 0/1
      const formData = new FormData(this.$refs.addDocumentForm);
      formData.set('shareable_with_client', formData.get('shareable_with_client') === 'on' ? 1 : 0);

      httpPost(`/${this.endpoint}/${id}/document/new`, formData)
        .then(({ status, document_uuid }) => {
          if (status !== 'ok') {
            this.error = status;
            return console.error(status);
          }

          this.$emit('add', { document_uuid, formData });
          this.closeModal('add-client-project-link');
          this.$refs.addDocumentForm.reset();
        })
        .catch(err => this.error = err);
    },
    deleteDocument(document) {
      const { id } = this.$route.params;

      httpDelete(`/${this.endpoint}/${id}/document/${document.document_uuid}`)
        .then(() => this.$emit('delete', document));
    },
  },
}
</script>